import { HistoryLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import rehypeReact from "rehype-react";
import { unified } from "unified";
import CtaButtons from "../components/CtaButtons";
import Seo from "../components/Seo";
import TextPageLayout from "../components/TextPageLayout";

interface Props {
  location: HistoryLocation;
  data: any;
}

// @ts-ignore
const processor = unified().use(rehypeReact, {
  createElement: React.createElement,
  components: { "cta-buttons": CtaButtons },
});
export const renderAst = (ast: any): JSX.Element => {
  return (processor.stringify(ast) as unknown) as JSX.Element
};


const MarkdownPage = ({ location, data }: Props) => {

  const { markdownRemark } = data; // data.markdownRemark holds your post data
  // @ts-ignore
  const { frontmatter, htmlAst } = markdownRemark;
  const { title, description, keywords, className } = frontmatter;

  return (
    <TextPageLayout title={title} location={location}>
      <Seo title={title} description={description} keywords={keywords} />
      <div className={`blog-post-content ${className}`}>{renderAst(htmlAst)}</div>
    </TextPageLayout>
  )
}

export default MarkdownPage;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      htmlAst
      frontmatter {
        slug
        title
        keywords
        description
        className
      }
    }
  }
`;
